<template>
  <span>
    <popover
      title="Enter a Markup"
      trigger="hover"
      :enterable="false"
      :show-delay="400"
      v-if="params.value.inputType === 'markup'"
    >
      <i
        class="fad fa-badge-percent fa-2x"
        data-role="trigger"
      />

      <template slot="popover">
        <table>
          <tbody>
            <tr>
              <th>Price</th>
              <td v-if="params.value.markup">{{ params.value.markup | currency(locale, currency, precision) }}</td>
              <td v-else>Missing</td>
            </tr>

            <tr>
              <th>Markup (Input)</th>
              <td v-if="entered">{{ entered * 0.01 | percent(locale, precision) }}</td>
              <td v-else>No Bid</td>
            </tr>

            <tr class="trfoot" v-if="placed">
              <th>Bid</th>
              <td>{{ placed | currency(locale, currency, precision) }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </popover>


    <tooltip
      custom-class="tooltip-bold"
      :text="title"
      :show-delay="400"
      :enterable="false"
      v-else
    >
      <span>{{ icon }}</span>
    </tooltip>
  </span>
</template>

<script>
  export default {
    computed: {
      icon() {
        return (this.params.value.inputType === 'price') ? this.params.context.symbol : '%'
      },
      title() {
        if (this.params.node.rowPinned) return '';
        let inputType = this.params.value.inputType;
        return 'Enter a ' + inputType.charAt(0).toUpperCase() + inputType.slice(1)
      },
      locale() {
        return this.params.context.locale;
      },
      currency() {
        return this.params.context.currency;
      },
      precision() {
        return this.params.data.precision
      },
      entered() {
        return this.params.value.bid
      },
      placed() {
        return this.params.value.placed
      }
    },
    filters: {
      currency(value, locale, currency, precision) {
        return new Intl.NumberFormat(locale, {
          currency,
          style: 'currency',
          minimumFractionDigits: precision
        }).format(value)
      },
      percent(value, locale, precision) {
        return new Intl.NumberFormat(locale, {
          style: 'percent',
          minimumFractionDigits: precision
        }).format(value)
      }
    }
  }
</script>
